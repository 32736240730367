import { Link } from "react-router-dom";

const RelatedBlog=()=>{
    return(
        <>
            <div className="container Blog-relate my-5 pt-5">
                <div className="row">
                    <div className="col-md-12 my-5">
                    <h1 style={{fontSize:'35px'}} className="mt-5"> Web Designing Blogs</h1>

                    </div>
                    <div className="col-md-4">
                        <Link to="/ecommerce-blog">
                        <div className="blog-relate-box p-4">
                            <div>
                        <img src={`${process.env.PUBLIC_URL}/images/blog/seoblog1.webp`} alt="web designing company in palakkad
"  loading='lazy' />
</div>
                        <div className="mt-3">
                        <h5>Crafting Successful E-commerce Sites</h5>

                        <p>The e-commerce landscape is booming! Experts predict global retail e-commerce sales to hit a whopping $6.33 trillion in 2024...</p>
                        </div>
                        </div>
                        </Link>
                    </div>

                   
                    <div className="col-md-4">
                    <Link to="/cheapest-web-design-company">
                        <div className="blog-relate-box p-4">
                            <div>
                        <img src={`${process.env.PUBLIC_URL}/images/blog/rel-blog.webp`} alt="web designing company in palakkad
"  loading='lazy' />
</div>
                        <div className="mt-3">
                        <h5>What can you expect from Price 2000 to 6000 Range website?</h5>

                        <p>When you're on a tight budget, it's tempting to aim for the cheapest web design company. However, aiming for the 2000-6000 range without...</p>
                        </div>
                        </div>
                        </Link>
                    </div>
                   


                   
                    <div className="col-md-4">
                    <Link to="/relatedBlog">
                        <div className="blog-relate-box p-4">
                            <div>
                        <img src={`${process.env.PUBLIC_URL}/images/blog/ai.webp`} alt="web designing company in palakkad
"  loading='lazy' />
</div>
                        <div className="mt-3">
                        <h5>How Artificial Intelligence (AI) Can Be Used to Improve Your Business Website</h5>

                        <p>In today’s digital-first world, having a website that stands out is critical for businesses. Artificial Intelligence (AI) offers groundbreaking ....</p>
                        </div>
                        </div>
                        </Link>
                    </div>
                    
                </div>
            </div>
        
        </>
    )
}

export default RelatedBlog;