
import React, { useState } from "react";


const EduManageFAQ = () => {
  // Define the state to handle which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Sample FAQ data
  const faqData = [
    { question: "What is a College Management System?", answer: "A College Management System (CMS) is an integrated software solution designed to streamline and automate the administrative tasks within a college or educational institution. It helps manage student information, faculty, courses, exams, library, and other operations efficiently." },
    { question: "How Can College Management Software Benefit Colleges in Kerala?", answer: "College Management Software in Kerala automates admissions, fee collection, attendance, and exams, ensuring smooth academic and administrative operations. It enhances communication, reduces manual workload, and improves overall efficiency in college management" },
    { question: "How does the College Management System help in managing admissions?", answer: "The CMS simplifies the entire admission process by allowing students to apply online, submit necessary documents, track their application status, and make payments through a secure platform. It centralizes all applicant data, reducing errors and enhancing workflow efficiency for the administration." },
    { question: "Can the system be accessed from both web and mobile platforms?", answer: "Yes, our College Management System is accessible on both web and mobile platforms, allowing administrators, students, and faculty to access the system anytime, anywhere, for greater flexibility and convenience." },
    { question: "How does the system improve communication between students, parents, and staff?", answer: "The CMS includes a communication system that enables easy interaction between students, parents, and staff. Features like messaging, notifications, and real-time updates keep everyone informed about important events, deadlines, and progress." },
    { question: "How does the CMS support library management?", answer: "The CMS includes a library management module to track book inventory, issue and return records, and overdue notifications." },
    { question: "Is the Software Customizable for Colleges in Kerala?", answer: "Yes, the software can be customized to meet the specific requirements of colleges in Kerala, including integrating with the local university guidelines, incorporating regional language support, and adapting to institution-specific workflows." }
    
   
];

  // Toggle the open state for each FAQ item
  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close if the same index is clicked again
    } else {
      setOpenIndex(index); // Open the clicked FAQ
    }
  };

  return (
    <div className="faq-container my-5">
      <h3>FAQs</h3>
      <div className="faq-list mt-2 ">
        {faqData.map((item, index) => (
          <div  
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""} mb-4` }
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{item.question}</span>
              <span className="faq-icon" style={{color: 'orange'}}>{openIndex === index ? "-" : "+"}</span>
            </div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EduManageFAQ;
