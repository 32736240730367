
import React, { useState } from "react";


const SchoolFAQ = () => {
  // Define the state to handle which FAQ is open
  const [openIndex, setOpenIndex] = useState(null);

  // Sample FAQ data
  const faqData = [
    { question: "What is TriceEdumanage?", answer: "TriceEduManage is an all-in-one school management system in Kerala that helps educational institutions efficiently manage and streamline their daily operations. It connects administrators, teachers, parents, and students on a single platform, ensuring seamless communication and effective school management." },
    { question: "What are the key features of TriceEdumanage?", answer: "The software offers a wide range of features, including modules for managing admissions, attendance, fees, exams, library, transportation, and staff. It also provides tools for generating reports and real-time updates." },
    { question: "Is TriceEdumanage compatible with mobile devices?", answer: "Yes, TriceEdumanage can be accessed on both web and mobile platforms, ensuring flexibility and convenience for users." },
    { question: "Does TriceEdumanage support online fee payments?", answer: "Yes, it includes a secure online payment gateway, allowing schools to manage tuition and other payments effortlessly." },
    { question: "How does TriceEdumanage enhance communication?", answer: "The platform facilitates better communication by enabling instant notifications, messages, and updates, ensuring that parents, teachers, and administrators stay informed." },
    { question: "Is this school management software designed for Kerala schools?", answer: "Yes, our school management system in Kerala is specifically designed to meet the needs of educational institutions across the state. It supports CBSE, ICSE, and Kerala State syllabus schools, offering features like student information management, attendance tracking, fee management, and seamless communication tools"}
   
];

  // Toggle the open state for each FAQ item
  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close if the same index is clicked again
    } else {
      setOpenIndex(index); // Open the clicked FAQ
    }
  };

  return (
    <div className="faq-container my-5">
      <h3>FAQs</h3>
      <div className="faq-list mt-2 ">
        {faqData.map((item, index) => (
          <div  
            key={index}
            className={`faq-item ${openIndex === index ? "open" : ""} mb-4` }
            onClick={() => toggleFAQ(index)}
          >
            <div className="faq-question">
              <span>{item.question}</span>
              <span className="faq-icon" style={{color: 'orange'}}>{openIndex === index ? "-" : "+"}</span>
            </div>
            <div className="faq-answer">{item.answer}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SchoolFAQ;
